<template>
  <b-overlay :show="loading">
    <b-container fluid class="mb-6 mt-6 p-0">
      <b-row>
        <b-col>
          <div class="card card-custom gutter-b">
            <div class="mt-0">
              <v-data-table
                :headers="headers"
                :items="data"
                item-key="id"
                :items-per-page="-1"
                group-by="group"
                :group-desc="true"
                class="systems-parameter"
                hide-default-footer
              >
                <template v-slot:header.name="{ header, items }">
                  <v-icon @click.stop="expandAll"
                    >mdi-{{
                      isExpandedNew
                        ? 'unfold-more-horizontal'
                        : 'unfold-less-horizontal'
                    }}
                  </v-icon>
                  {{ header.text }}
                </template>

                <template
                  v-slot:group.header="{
                    toggle,
                    group,
                    isOpen,
                    headers,
                    items,
                  }"
                >
                  <td class="pl-0 no-background">
                    <div class="d-flex justify-content-between">
                      <div>
                        <v-btn
                          @click="toggle"
                          :ref="group"
                          :data-open="isOpen"
                          icon
                        >
                          <v-icon
                            >mdi-{{
                              isOpen ? 'chevron-down' : 'chevron-up'
                            }}</v-icon
                          >
                        </v-btn>

                        <span class="font-weight-boldest">
                          {{ convertGroupTitle(group) }}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td></td>
                  <td>
                    <span class="summary">
                      {{ items[0].totalCalories }}
                      <span class="unit" style="font-weight: normal">kcal</span>
                    </span>
                  </td>

                  <td></td>
                  <td></td>
                </template>
                <template v-slot:item="{ item, expand, isExpanded }">
                  <tr>
                    <td class="pt-2 pb-2">
                      <img
                        class="rounded-sm thumb mr-2"
                        :src="item.image"
                        alt=""
                      />
                      {{ item.name }}
                    </td>
                    <td>
                      <div class="d-flex">
                        <span class="d-flex align-items-center">
                          <span class="exercise-icon mr-2"></span>
                          {{ item.portion }}</span
                        >
                        <span class="unit pl-2"> {{ item.unit }}</span>
                      </div>
                    </td>
                    <td>
                      {{ item.calories }}
                      <span class="unit">kcal</span>
                    </td>
                    <td>{{ item.note }}</td>
                    <td>
                      <div class="d-flex">
                        <div
                          class="preview"
                          v-for="(image, index) in item.images"
                          :key="index"
                        >
                          <img
                            @click.stop="handlePreview(image.url)"
                            :src="image.url"
                            class="preview-image"
                            alt=""
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <image-preview :image.sync="imagePreview" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script>
// Requiring the lodash library
const _ = require('lodash');
export default {
  components: {
    'image-preview': () => import('../components/ModalPreview.vue'),
  },
  name: 'FoodTable',
  props: {
    time: {
      type: Object,
    },
    idUser: {
      type: [String, Number],
    },
  },
  data() {
    return {
      isExpandedNew: true,
      isExpandedOld: true,
      headers: [
        { text: 'Ngày giờ nhập / Món ăn', value: 'name', sortable: false },
        { text: 'Khẩu phần', value: 'ration', sortable: false },
        { text: 'Số kcal', value: 'kcal', sortable: false },
        { text: 'Ghi chú', value: 'note', sortable: false },
        {
          value: 'thumbnail',
          text: 'Hình ảnh',
          sortable: false,
        },
      ],
      data: [],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      searchParams: {
        currentDateTime: parseInt(new Date().getTime() / 1000),
        page: 1,
        periodFilterType: 1,
        size: 1000,
      },
      imagePreview: null,
      loading: false,
    };
  },
  computed: {
    id() {
      return this.idUser;
    },
  },
  watch: {
    time: {
      handler(newVal) {
        this.searchParams.periodFilterType = newVal.value;
        this.loadData();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handlePreview(image) {
      this.imagePreview = image;
      this.$bvModal.show('image-preview');
    },

    loadData() {
      this.loading = true;
      this.$api
        .get(`Admin/Diet/Input`, {
          params: { ...this.searchParams, patientId: this.id },
        })
        .then(({ meta, data }) => {
          this.data = data.items
            .map((e) => {
              return e.foods.map((item) => ({
                session: e.mealText,
                group: `${this.$moment(e.date * 1000).format(
                  'YYYY-MM-DD HH:MM',
                )}, ${e.mealText}`,
                date: `${this.$moment(e.date * 1000).format(
                  'HH:MM DD/MM/YYYY',
                )}, ${e.mealText}`,
                calories: item.caloriesPerUnit * item.portion,
                note: e.note,
                image: item.image?.url,
                name: item.name,
                portion: item.inputPortion,
                images: e.images,
                unit: item.unit,
                totalCalories: e.calorie,
              }));
            })
            .reduce((a, b) => a.concat(b), []);
          // Sort manual by date
          _.sortBy(this.data, ['group'], ['desc']);

          this.paging.total = meta.total;
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        })
        .finally(() => {
          this.loading = false;
        });
      return;
    },
    summaryNumber(items, key) {
      return items.reduce((acc, item) => acc + Number(item[key]), 0);
    },
    expandAll() {
      this.isExpandedNew = !this.isExpandedOld;
      // Filter vue-components
      Object.keys(this.$refs)
        .filter((e) => this.$refs[e]?.$el)
        .forEach((k) => {
          // Check if element has already open
          const status = this.$refs[k].$attrs['data-open'];
          if (status != this.isExpandedNew) {
            this.$refs[k].$el.click();
          }
          return;
        });
      this.isExpandedOld = this.isExpandedNew;
    },

    convertGroupTitle(group) {
      let [date, meal] = group.split(',');
      return `${this.$moment(date).format('HH:MM DD/MM/YYYY')}, ${meal}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.unit {
  color: #b5b5c3;
}
.summary {
  color: #21a567;
  font-weight: bold;
}
.thumb {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.preview {
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.preview:not(:first-child) {
  margin-left: 10px;
}
.preview-image {
  border-radius: 6px;
  display: block;
  width: 100%;
  object-fit: cover;
  height: 100%;
}
</style>
